// see src/stylesheets/sites/_common.scss
$product-category-header-bg: inherit; // product category header bg color
$product-category-active-filters-bg: inherit; // product category active filters bg color
$product-category-groups-filters-bg: inherit; // product category filter groups bg color
$product-category-rounded: false; // apply a rounded border to product category sections

// override default colors
$dark: #000000;
$warning: #d4c251;
$success: #477d60;
$danger: #733049;

// override main menu colors
$menu-bg: #383a40;
$menu-dropdown-bg: #383a40;

// override Campaign outlet button color
$outlet-btn-color: #f98727;

// override breadcrumbs colors (fg|display-home MUST be present)
$breadcrumb-fg: inherit; // use `inherit` for default color
$breadcrumb-display-home: inherit; // use `none` to hide home icon, default to `inherit`
// $breadcrumb-bg: inherit; // breadcrumb bg color
// $breadcrumb-divider-color: inherit; // breadcrumb divider item color
// $breadcrumb-active-color: inherit; // breadcrumb active item fg color

// override Campaign buygift button color
$buygift-btn-color: #383a40;

// override route links color
$link-color: #9e4276;

// override product subcategory color
$product-subcategory-bg: #e7e4e4;
$product-subcategory-fg: $dark;

// override product discount ribbon color
$discount-ribbon-bg:  #B06A93;
$discount-ribbon-fg: $white;

// override assembly-page card-deck-overlay color
$card-deck-overlay-bg: #954875;
// disable the overlay opacity
$card-deck-opaque-overlay: false;

// override product-serie-list-item color
$product-series-summary-bg: #e3e2e1;
$product-series-summary-fg: #000000;
$product-series-button-bg: #954875;

.valentines {
  background-color: #df7899 !important;
}

.monthly {
  background-color: #b16a94 !important;
}

.cybermonday {
background-color: #071249 !important;
color: #ff01ba !important;
}

.greybox {
    background-color: #ebe9e9;
}

.blackmonth {
  background-color: #000000 !important;
}

.cyber {
  background-color: #007ea6 !important;
}

.grey {
  background-color: #e3e2e2 !important;
}